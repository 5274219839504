.page-recruitment_detail {
  .section-initial {
    .tds-flex {
      position: relative;
    }
  }
  .listing-meta {
    clear: both;
    .meta-title, .meta-description {
      margin-bottom: 17px;
      display: block;
      float: left;
      width: 160px;
    }
    .meta-title {
      font-weight: 700;
    }
  }

  .section-disclaimer p {
    font-size: 12px;
    margin: 22px 0;
  }

  .section-jobdescription ul {
    margin: 22px;
    list-style: disc;
  }

  .tds-btn {
    margin: 22px 0
  }

  p {
    margin-bottom: 22px;
    margin-top: 0;
    padding-block-start: 0;
    max-width: none;
  }
}