.page-ausbildung, .page-apprenticeship {
  .intro-video {
    width: 100%;
    background: #000;
    min-height: 100px;
    margin-bottom: 50px;
  }
  .intro-block {
    text-align: center;
    max-width: 75%;
    margin: 50px auto;
    background: #f2f2f2;
    img {
      height: 100%;
      width: 100%;
      max-width: 100%;
    }
    .tds-flex-item {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
    p {
      max-inline-size: none;
      padding: 0 50px;
    }
    h1 {
      padding: 0;
    }
  }

  .training-day {
    h1 {
      font-size: 24px;
    }
    p {
      text-align: left;
      margin-bottom: 10px;
    }
  }

  .award-photo {
    width: 700px;
    max-width: 100%;
  }

  .tds-card {
    .section-image {
      inline-size: 100%;
    }
    .tds-card-body {
      padding-bottom: calc(90px + var(--tds-card--padding));
      height: 100%;
    }
  }

  .apprenticeship-links {
    .tds-flex-item {
      flex: 0;
      padding: 0;
      padding-right: var(--tds-size--3x);
    }
  }


  .training-day .intro-block-text {
    align-items: start;
    padding: var(--tds-size--6x);
  }


  .training-day .intro-block-text .tds-list {
    text-align: left;
    padding-block-start: 0;
    padding-block-end: var(--tds-paragraph-gap);
  }

  .intro-block.training-day .intro-block-text p {
    padding: 0;
  }

  @media (max-width: 599px) {
    .intro-video {
      margin-bottom: 0;
    }
    .intro-block-text {
      padding: 24px 0;
    }
    .intro-block {
      flex-flow: column wrap;
      margin: 0;
      max-width: 100%;
    }
    .intro-block:nth-child(even) {
      flex-flow: column-reverse wrap;
    }
    .intro-text {
      padding: 0 25px;
    }
    .apprenticeship-links {
      flex-flow: row wrap;
      & {
        margin-top: 15px;
      }
      .tds-flex-item {
        margin-top: 5px;
      }
    }
    .tds-card .tds-card-body {
      padding-bottom: 30px;
    }
  }
  @media (min-width: 600px) {
    .apprenticeship-links {
      position: absolute;
      bottom: 30px;
    }
  }
  @media (max-width: 1000px) and (min-width: 600px) {
    .section-cards > .tds-flex {
      margin-block-start: 0;
      margin-block-end: 0;

      .tds-flex-item {
        inline-size: 50%;
      }
    }
    .intro-block {
      padding: 20px 0;
      flex-flow: column wrap;
      .tds-flex--col_1of2 {
        inline-size: auto;
      }
      img {
        width: auto;
        height: auto;
        margin-top: 20px;
      }
    }
    .intro-block:nth-child(even) {
      flex-flow: column-reverse wrap;
    }
  }
  .section-image img {
    max-width: 100%;
  }
}