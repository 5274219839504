.page-recruitment {
  .section-cards {
    .tds-flex {
      justify-content: center;
    }
  }
}

img.benefits {
  max-width: 1440px;
  width: 100%;
  height: auto;
  margin: 0 auto;
}

.careers-app {
  ul {
    list-style-type: initial;
    margin: 20px;
  }
  .disclaimer {
    font-size: 12px;
  }
  .tds-list-item_title {
    font-weight: bold;
  }
  .tds-list-item_value {
    display: block;
  }
  p {
    max-width: none;
  }
  .careers-filter {
    border-radius: var(--tds-size);
    padding: 18px 12px 12px;
  }
  .description {
    margin: 2rem 0;

    a:not(.tds-link),
    a:not(.tds-btn) {
      text-decoration: underline;
    }
  }

  .tds-o-careers-layout.tds-layout {
    padding-left: 0;
    padding-right: 0;
  }

  .tds-o-careers-layout.tds-layout-main--right .tds-layout-main {
    padding-left: 0;
  }

  .tds-o-careers-layout.tds-layout-2col-static_sidebar.tds-layout-main--right {
    grid-template: 'aside main' 1fr / minmax(230px,2fr) 10fr;

    @media (max-width: 640px) {
      grid-template: none;
      display: flex;
      flex-direction: column-reverse;
    }
  }

  .tds-o-careers-layout .tds-layout-aside {
    @media (max-width: 640px) {
      margin: 2rem 0;
    }
  }

  .tds-table.tds-o-details-table {
    margin-bottom: 2rem;
  }

  .tds-table.tds-o-details-table .tds-table-row th,
  .tds-table.tds-o-details-table .tds-table-row td {
    border-top: none;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .tds-table.tds-o-details-table .tds-table-row th {
    width: 200px;

    @media (max-width: 640px) {
      padding-bottom: 0;
    }
  }

  .tds-spinner {
    background: rgba(0, 0, 0, 0.5);
  }

  .highlighted {
    background-color: #FFF2AC;
  }

}
