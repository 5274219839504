.page-contact {
  .section-cards {
    .tds-card-body {
      padding-bottom: calc(60px + var(--tds-card--padding));
    }

    .contact-card-footer {
      position: absolute;
      bottom: 15px;
    }
  }

  .worldwide-offices {
    .tds-card-body {
      padding-bottom: 24px;
      h4 {
        padding: 0;
      }
    }
  }
}