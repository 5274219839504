.page-homepage {
  .section-content {
    margin-top: 0;
    padding-top: 0;
  }
  .section-title {
    padding-top: 0;
  }
  .tds-content_container h1 {
    font-size: 32px;
    margin-top: 60px;
  }
  .paragraph-image img {
    width: 100%;
    margin: 25px auto 10px auto;
    max-inline-size: 75ch;
  }
}

