body {
  background: #fafafa;
}

.tds-site-header {
  background: #fff;
}

.lang-selector-de {
  margin-left: 30px;
  a {
    border-right: 1px solid #ddd;
    padding-right: 15px;
  }
}

.locale-de {
  .lang-selector-en a {
    color: #999;
  }
}

.locale-en {
  .lang-selector-de a {
    color: #999;
  }
}

@media (max-width: 640px) {
  .lang-selector-de {
    position: relative;
    left: -45px;
  }
  .lang-selector-en {
    position: relative;
    top: -38px;
    left: 40px;
  }
  .lang-selector-en a {
    padding-left: 15px;
  }
}

.tds-site-nav-content {
  .tds-site-nav-item {
    justify-content: initial;
  }
  .tds-site-nav-item {
    &.selected, &:hover {
      color: rgb(102, 102, 102);
    }
  }
}

.tds-site-footer {
  border-top: 1px solid #e5e5e5;
  padding: 15px 0;
  margin-top: 70px;
}

.section-title {
  padding: 24px 0;
}

h1 {
  font-size: 28px;
}

h2 {
  font-size: 24px;
}

.section-content {
  margin: 20px 0;
  padding: 20px;
  text-align: center;
  p {
    margin: 0 auto;
  }
  .tds-btn {
    margin-top: 20px;
  }
}

.section-cards {
  margin: 20px 0;
  .tds-card {
    width: 100%;
    height: 100%;
    position: relative;
    &:hover {
      box-shadow: var(--tds-box-shadow--medium);
    }
    .tds-card-body {
      padding-bottom: calc(30px + var(--tds-card--padding));
    }
    .info-pdf {
      position: absolute;
      right: 25px;
      bottom: 30px;
      a {
        margin: 0 5px;
      }
    }
    @media (max-width: 640px) {
      margin-bottom: 20px;
    }
  }
}

.section-divider {
  margin: 15px auto 0;
  max-width: 800px;
  width: 100%;
  height: 0;
  border-bottom: 1px solid #e5e5e5;
}

.section-hero {
  position: relative;
  .hero-images {
    position: relative;
    overflow: hidden;
  }
  .section-image {
    width: 100%;
    opacity: 0;
    top: 0;
    left: 0;
    position: absolute;
    -webkit-transition: opacity 2s ease-in-out;
    -moz-transition: opacity 2s ease-in-out;
    transition: opacity 2s ease-in-out;

    &:first-child {
      position: inherit;
    }

    &.visible {
      opacity: 1;
    }

    img  {
      display: block;
      max-width: 100%;
      margin: 0 auto;
    }
  }
}

a[href^="mailto"] {
  color: var(--tds-text--color--primary);
  box-shadow: 0 0 0 0 transparent;
  font-weight: var(--tds-font-weight--medium, 500);
  white-space: nowrap;
}

.info-box {
  background: #eee;
  margin: 20px 0;
  h3 {
    padding: 0;
  }
}

#privacy-policy-modal .tds-modal-content {
  overflow-y: scroll;
}